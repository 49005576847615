import React, {useState} from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Slider from "../components/Slider";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import Layout from "../components/Layout";
import Header from "../components/Header";
import BookVisit from "../components/BookVisit";
import Footer from "../components/Footer";
import SocialLinks from "../components/SocialLinks";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-solid-svg-icons/faSun";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons/faSnowflake";
import { faCouch } from "@fortawesome/free-solid-svg-icons/faCouch";
import { faWifi } from "@fortawesome/free-solid-svg-icons/faWifi";
import { faTree } from "@fortawesome/free-solid-svg-icons/faTree";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";
library.add(...[faSun,faUtensils,faUsers,faWifi,faTree, faSnowflake, faCouch]);

const Gallery = ({ data, galleryPath, desc = "" }) => {
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const images = data.allFile.edges
    .map(
      ({ node }) =>
        node.relativePath.indexOf(galleryPath) === 0 && node.childImageSharp
    )
    .filter((e) => e)
  const id = galleryPath.replace(/\//g, "");
  let prevIndex = index - (1 % images.length);
  let nextIndex = (index + images.length + 1) % images.length;
      prevIndex = prevIndex < 0 ? images.length - 1 : prevIndex;
  const lightboxOptions = {
    
  };
  // `images` is an array of objects with `thumb` and `full`
  return (
    <>
      <Slider
        className="h-100"
        content={images.map((img, idx) => (
          <div
            className={"h-100"}
            style={{"minHeight":"75vh"}}
            key={`${desc} ${idx}`}
            onClick={() => {
              setIndex(idx);
              setIsOpen(!isOpen);
            }}
          >
            <BackgroundImage
              Tag="div"
              style={{ cursor: "pointer" }}
              className={"h-100"}
              alt={`EMMIM® - ${desc} ${idx}`}
              fluid={img.thumb}
            ></BackgroundImage>
          </div>
        ))}
      />
      {isOpen && (
        <Lightbox
          mainSrc={images[index].full.src}
          nextSrc={images[nextIndex].full.src}
          prevSrc={images[prevIndex].full.src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
          {...lightboxOptions}
        />
      )}
    </>
  );
};

const Icon = ({icon, desc}) => {
  return (
    <Fade bottom>
      <div className="col">
        <div>
          <FontAwesomeIcon icon={icon} className="iconBlock" />
        </div>
        <p className="text-secondary">
        {desc}
        </p>
      </div>
    </Fade>
  );
}
const IndexPage = ({ data }) => {
  console.log(data)
  return (
    <Layout
      title={"O que encontrá no nosso espaço?"}
      description="um ambiente pensado ao detalhe, acolhedor e confortável tanto para os profissionais como para os pacientes"
    >
      <Header
        title="O que encontrá no nosso espaço?"
        smallSubtitle="um ambiente pensado ao detalhe, acolhedor e confortável tanto para os profissionais como para os pacientes"
        active="espaco"
        showHeader={false}
        className="rooms"
      />

      <div className="content bg-fg-withLogo noGutters pt-5">
        <div className="col-sm-12 text-center p-5  text-primary triangle-bottom">
          <h1 className="pt-5">
            O que encontra no <span className="destak">nosso espaço?</span>
          </h1>
          <h4 className="text-color pt-5">
            Ambiente pensado ao detalhe, acolhedor e confortável
          </h4>
          <h4 className="text-color">
            tanto <span className="destak">para si</span> como para os{" "}
            <span className="destak">seu pacientes</span>
          </h4>
          <div className="row justify-content-md-center p-5">
            <Icon icon="snowflake" desc="ar condicionado" />
            <Icon icon="sun" desc="Luz Natural" />
            <Icon icon="couch" desc="Sala de espera" />
            <Icon icon="wifi" desc="Wifi" />
            <Icon icon="tree" desc="Espaços Exteriores" />
            <Icon icon="users" desc="Recepção" />
            <Icon icon="utensils" desc="copa" />
          </div>
          <h1 className="text-color">Conheça o seu consultório</h1>

          <h3 className="text-color p-5">
            Sabia que todos os nossos consultórios tem nomes de emoções?
          </h3>
        </div>
        <div className="content container-fluid gray-100-bg">
          <div className="row justify-content-md-center fg-bg no-gutters">
            <Fade>
              <div
                className="col-sm-12 col-md-6 order-sm-1 text-center  pl-5 pr-5  text-color"
                style={{ minHeight: "50vh" }}
              >
                <h1 className="text-primary p-3">Triumph</h1>
                <p>
                  A <span className="destak text-secondary">Triumph</span>{" "}
                  domina pela versatilidade do espaço, que se adapta facilmente
                  às diferentes situações e necessidades que possam surgir.{" "}
                </p>
                <p>
                  Equipada por uma secretária com cadeiras, duas poltronas com
                  apoio para os pés, um espaçoso móvel de apoio, ainda tem ao
                  seu dispor a possibilidade de colocar uma marquesa e um banco
                  ergonómico com rodas.
                </p>
                <p>
                  {" "}
                  O acesso à varanda permite-o desfrutar de uma pausa com uma
                  vista maravilhosa sobre a Praça Duque de Saldanha.{" "}
                </p>
                <p>
                  Apesar de se adequar a várias terapias, é ideal para todos os
                  profissionais que trabalham na área da saúde mental,
                  desenvolvimento pessoal, entre outras terapias (ex: terapia da
                  fala).
                </p>
              </div>
              <div className="col-sm-12 col-md-6 order-sm-2 text-center text-primary ">
                <Gallery
                  desc="Aluguer consultório em Lisboa para psicólogos,
                  hipnoterapeutas, médicos psiquiatras, terapeutas da fala, coach's etc"
                  data={data}
                  galleryPath="gallery/triumph"
                />
              </div>
            </Fade>
          </div>

          <div className="row justify-content-md-center no-gutters">
            <Fade>
              <div
                className="col-sm-12 col-md-6 text-center order-md-2 pl-5 pr-5 text-color"
                style={{ minHeight: "50vh" }}
              >
                <h1 className="text-primary p-3">Admiration</h1>
                <p>
                  A <span className="destak text-secondary">Admiration</span>{" "}
                  preza pelo aconchego do espaço, decoração e luz natural.
                </p>
                <p>
                  Equipada por duas poltronas, uma delas reclinável e com apoio
                  para os pés, e um móvel de apoio.
                </p>
                <p>
                  Diferencia-se pela sua mesa redonda em vidro permitindo assim
                  a possibilidade de realizar ações de formação ou supervisão
                  clínica com pequenos grupos.
                </p>
                <p>
                  Adequa-se a várias terapias e é ideal para todos os
                  profissionais que trabalham na área da saúde mental
                  (psicólogos, médicos psiquiatras), desenvolvimento pessoal,
                  terapeutas da fala entre outros.
                </p>
              </div>
              <div className="col-sm-12 order-md-1 col-md-6">
                <Gallery
                  desc="consultório para psicólogos, médicos, coach's"
                  data={data}
                  galleryPath="gallery/admiration/"
                />
              </div>
            </Fade>
          </div>
          <div className="row justify-content-md-center fg-bg no-gutters">
            <Fade>
              <div
                className="col-sm-12 col-md-6 order-sm-1 text-center  pl-5 pr-5  text-color"
                style={{ minHeight: "50vh" }}
              >
                <h1 className="text-primary p-3">Calm</h1>
                <p>
                  A <span className="destak text-secondary">Calm</span> está
                  equipada com marquesa, mesa redonda, duas poltronas, uma delas
                  reclinável e com apoio para os pés, móvel de apoio e rodeada
                  por um ambiente natura e relaxante.
                </p>
                <p>
                  Adequada a várias terapias, é ideal para todos os
                  profissionais que trabalham na área da saúde mental,
                  desenvolvimento pessoal ou terapias realizadas em marqueza.
                </p>
                <p>
                  Desfrute da serenidade que a nossa{" "}
                  <span className="destak text-secondary">Calm</span> oferece.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 order-sm-2 text-center text-primary ">
                <Gallery
                  desc="Calm"
                  data={data}
                  galleryPath="gallery/calm"
                />
              </div>
            </Fade>
          </div>
 
          <div className="row justify-content-md-center fg-bg no-gutters">
            <Fade>
              <div
                className="col-sm-6 text-center  pl-5 pr-5  order-md-2 text-color"
                style={{ minHeight: "50vh" }}
              >
                <h1 className="text-primary p-3">Happiness</h1>
                <p>
                  A <span className="destak text-secondary">Happiness</span>{" "}
                  conquista-nos pela decoração e luminosidade que nos envolve de
                  energia positiva.
                </p>
                <p>
                  Aqui encontra uma marquesa e um banco ergonómico com rodas bem
                  como um móvel de apoio.
                </p>
                <p>
                  Para as primeiras consultas, e sempre que precisar, tem ao seu
                  dispor uma secretária com cadeiras.{" "}
                </p>
                <p>Adequa-se a todas as terapias realizadas em marquesa. </p>
                <p>
                  Crie momentos de bem-estar e felicidade aos seus clientes na
                  nossa <span className="destak text-secondary">Happiness</span>{" "}
                  .
                </p>
              </div>
              <div className="col-sm-6 text-center order-md-1 text-primary">
                <Gallery
                  desc="Aluguer consultório com marquesa em Lisboa"
                  data={data}
                  galleryPath="gallery/happiness"
                />
              </div>
            </Fade>
          </div>
          <div className="row justify-content-md-center fg-bg no-gutters">
            <Fade>
              <div
                className="col-sm-6 text-center  pl-5 pr-5  text-color"
                style={{ minHeight: "50vh" }}
              >
                <h1 className="text-primary p-3">Wish</h1>
                <p>
                  A <span className="destak text-secondary">Wish</span> é uma
                  sala onde sobressaem os seus tons azuis na grande janela de
                  parede a parede.
                </p>
                <p>
                  Equipada por duas poltronas, uma delas reclinável e com apoio
                  para os pés, secretária e um móvel de apoio.
                </p>
                <p>
                  Adequa-se a várias terapias e é ideal para todos os
                  profissionais que trabalham na área da saúde mental
                  (psicólogos, médicos psiquiatras), desenvolvimento pessoal,
                  terapeutas da fala entre outros.
                </p>
              </div>
              <div className="col-sm-6 text-center text-primary">
                <Gallery
                  desc="Aluguer consultório com marquesa em Lisboa"
                  data={data}
                  galleryPath="gallery/wish"
                />
              </div>
            </Fade>
          </div>
          <div className="row justify-content-md-center no-gutters">
            <Fade>
              <div
                className="col-sm-6 text-center  pl-5 pr-5 order-md-2 text-color"
                style={{ minHeight: "50vh" }}
              >
                <h1 className="text-primary p-3">Worship</h1>
                <p>
                  A <span className="destak text-secondary">Worship</span>{" "}
                  carateriza-se pela sua grandiosidade e luz natural. Aqui
                  encontra uma marquesa e um banco ergonómico com rodas.
                </p>
                <p>
                  Poderá, ainda, quando necessário, utilizar uma segunda
                  marquesa e atender dois clientes em simultâneo.{" "}
                </p>
                <p>
                  Encontra-se, também, equipada por um espaçoso móvel de apoio e
                  uma balança digital.{" "}
                </p>
                <p>
                  Para as primeiras consultas, e sempre que precisar, tem ao seu
                  dispor uma secretária com cadeiras.
                </p>
                <p>
                  O acesso à varanda permite-o desfrutar de uma pausa com uma
                  vista maravilhosa sobre a Praça Duque de Saldanha.
                </p>
                <p>Adequa-se a todas as terapias realizadas em marquesa. </p>
                <p>
                  Se já é apaixonado pelo seu trabalho, imagine quando começar a
                  receber os seus clientes na nossa admirável{" "}
                  <span className="destak text-secondary">Worship</span> .
                </p>
              </div>
              <div className="col-sm-6 text-center order-md-1 text-primary">
                <Gallery
                  desc="Aluguer consultório com marquesa em Lisboa"
                  data={data}
                  galleryPath="gallery/worship"
                />
              </div>
            </Fade>
          </div>
          <div className="row justify-content-md-center fg-bg no-gutters">
            <Fade>
              <div
                className="col-sm-6 text-center  pl-5 pr-5  text-color"
                style={{ minHeight: "50vh" }}
              >
                <h1 className="text-primary p-3">Lounge</h1>
                <p>
                  Esta é uma zona privada apenas para os nossos profissionais.
                </p>
                <p>
                  Aqui poderá aproveitar para tomar uma refeição ou recuperar a
                  sua energia para continuar a sua jornada.
                </p>
                <p>
                  Poderá aproveitar o espaço ao ar livre para responder a algum
                  e-mail, ler um pouco ou simplesmente apanhar algum ar.
                </p>
              </div>
              <div className="col-sm-6 text-center text-primary">
                <Gallery
                  desc="Lounge"
                  data={data}
                  galleryPath="gallery/loungeArea"
                />
              </div>
            </Fade>
          </div>
     
          <div className="row justify-content-md-center fg-bg no-gutters">
            <Fade>
              <div
                className="col-sm-6 text-center order-md-2 pl-5 pr-5  text-color"
                style={{ minHeight: "50vh" }}
              >
                <h1 className="text-primary p-3">Recepção e sala de espera.</h1>
                <h4 className="pb-3">
                  É aqui que começa a arte de bem receber
                </h4>
                <p>
                  Trata-se de uma área que dá acesso a cada um dos consultórios
                  e sala de espera.
                </p>
                <p>
                  Os seus pacientes serão recebidos pela nossa equipa e
                  encaminhados à nossa sala de espera onde poderão aguardar
                  confortavelmente pela sua vez.
                </p>
                <p>Enquanto esperam, poderão tomar um café, água ou chá.</p>
              </div>
              <div className="col-sm-6 text-center order-md-1 text-primary">
                <Gallery
                  desc="Áreas comuns"
                  data={data}
                  galleryPath="gallery/commonArea"
                />
              </div>{" "}
            </Fade>
          </div>
        </div>
        <Zoom>
          <div className="gray-100-bg p-5">
            <div className="container-fluid">
              <div className="row p-5 justify-content-md-center text-center">
                <div className="col col-md-10">
                  <h1 className="p-5">
                    O nosso conceito vai muito além de um{"  "}
                    <span className="destak text-primary">espaço</span>
                  </h1>
                </div>
                <div className="col-sm-12 col-md-10 p-3">
                  <h3>
                    Queremos que sinta que este espaço{" "}
                    <span className="destak text-primary">também é seu!</span>
                  </h3>
                </div>
                <div className="col-sm-12 col-md-10">
                  <h5>
                    ( Podemos adaptar as salas à medida das suas necessidades )
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </Zoom>
        <div className="container"></div>
      </div>
      <BookVisit />
      <SocialLinks />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ImagesForGallery {
    allFile(
      filter: { relativePath: { regex: "/gallery/" } }
      sort: { order: ASC, fields: [name] }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            thumb: fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    waitingArea: file(relativePath: { eq: "waitingArea.jpg" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    lounge: file(relativePath: { eq: "lounge.jpg" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    loungeExt: file(relativePath: { eq: "loungeExt.jpg" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    shower: file(relativePath: { eq: "shower.jpg" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    kitchen: file(relativePath: { eq: "kitchen.jpg" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    reception: file(relativePath: { eq: "reception.jpg" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
